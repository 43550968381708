import React from "react";
import { NoCazzenGame } from "./nocazzen/NoCazzen";

export function GameSelector(props) {
  if (props.gameName === "No Cazzen!") {
    // Creare lo stato iniziale (es. makeInitialState(...props))
    return (
      <NoCazzenGame
        game={props.currentGameInstance}
        user={props.localUser}
      ></NoCazzenGame>
    );
  }
  if (props.gameName === "Avalon") {
    return (
      <div style={{ wordWrap: "break-word" }}>
        {props.gameName} with additional info {JSON.stringify(props)}
      </div>
    );
  }

  return <div>No game called {props.gameName}</div>;
}
