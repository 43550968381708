import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Home } from "./Home";
import { Room } from "./Room";

const App = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/" children={<Home />} />
        <Route path="/:roomId" children={<Room />} />
      </Switch>
    </Router>
  );
};

export default App;
