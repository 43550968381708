import React, { useState, useEffect } from "react";
import { Button } from "semantic-ui-react";
import { CommandDAO } from "../../apis/CommandDAO";
import { Logic, Commands, getCardsScore } from "./nocazzen-logic";
import styles from "./nocazzen-style.module.css";

function CardsShow(props) {
  return (
    <div className={styles.userCardsShow}>
      {props.userCards.map((card) => (
        <div className={styles.userCard}>{card}</div>
      ))}
    </div>
  );
}

export function NoCazzenGame({ game, user }) {
  const initState = Logic.makeInitialState(game);

  const [gameState, setGameState] = useState(initState);
  const [stopButtons, setStopButtons] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const commands = await CommandDAO.getCommandDatas(game.id);

      setGameState((prevState) => {
        let state = prevState;
        commands.forEach((command) => {
          state = Logic.transitionState(state, command);
        });
        return state;
      });
    };

    fetchData();

    const unsubscribe = CommandDAO.subscribeToCommands(
      game.id,
      (commandData) => {
        setGameState((prevState) =>
          Logic.transitionState(prevState, commandData)
        );
        setStopButtons(false);
      }
    );

    return () => {
      unsubscribe();
    };
  }, [game]);

  const handleNoClick = () => {
    CommandDAO.saveCommand(Commands.No(user.id), game.id);
    setStopButtons(true);
  };
  const handleTakeClick = () => {
    CommandDAO.saveCommand(Commands.Yes(user.id), game.id);
    // setStopButtons(true);
  };

  const currentPlayerTurn = gameState.currentPlayer === user.id;
  const endOfGame = gameState.currentDeckIndex === gameState.deck.length;
  const cannotPlay = stopButtons || !currentPlayerTurn || endOfGame;

  const currentPlayer = gameState.OfPlayer[gameState.currentPlayer].displayName;
  const currentCard = gameState.deck[gameState.currentDeckIndex];
  const counters = gameState.counters;
  let userCards =
    user.id in gameState.OfPlayer
      ? gameState.OfPlayer[user.id].getSortedCards()
      : [];

  const userCardsScore = getCardsScore(userCards);
  const userCounters =
    user.id in gameState.OfPlayer ? gameState.OfPlayer[user.id].counters : 0;
  const userScore = userCardsScore - userCounters;

  const otherPlayers = Object.entries(gameState.OfPlayer)
    .filter(([key, value]) => key !== user.id)
    .map(([key, value]) => {
      return {
        displayName: value.displayName,
        cards: value.cards.sort((a, b) => a - b),
        score: value.getScore(),
      };
    });
  const cannotSayNo = userCounters === 0;

  return (
    <div className={styles.display}>
      <div className={styles.playerInfo}>
        {endOfGame ? (
          <h5>
            <span className={styles.emphasisRed}>End of Game!</span>
          </h5>
        ) : (
          <h5>
            <span className={styles.emphasisRed}>{currentPlayer}</span> should
            play
          </h5>
        )}
        <div className={styles.cardAndCounters}>
          <div className={styles.itemAndSubtitle}>
            <div className={styles.card}>
              <span className={styles.cardText}>{currentCard}</span>
            </div>
            <p>cazzen</p>
          </div>

          <div>
            <div className={styles.counterBox}>
              {[...Array(counters)].map(() => (
                <div className={styles.counter}></div>
              ))}
            </div>
          </div>
        </div>

        <div>
          <Button
            color="black"
            onClick={handleNoClick}
            disabled={cannotPlay || cannotSayNo}
          >
            No Cazzen!
          </Button>
          <Button color="black" onClick={handleTakeClick} disabled={cannotPlay}>
            Yes, I take it
          </Button>
        </div>
        <h5>My Cazzens</h5>
        <CardsShow userCards={userCards}></CardsShow>

        <h5>My Goldons</h5>
        <div className={styles.userCounters}>
          {[...Array(userCounters)].map(() => (
            <div className={styles.counter}></div>
          ))}
        </div>

        <h5>My Score</h5>
        <div className={styles.scores}>
          <div className={styles.itemAndSubtitle}> {userCardsScore}</div>
          <div>-</div>
          <div className={styles.itemAndSubtitle}> {userCounters}</div>
          <div> = </div>
          <div className={styles.itemAndSubtitle}> {userScore}</div>
        </div>
      </div>

      <div className={styles.otherPlayersInfo}>
        {otherPlayers.map((player) => (
          <>
            <h5>
              <span className={styles.emphasisBlue}>{player.displayName}</span>{" "}
              Cazzens
            </h5>
            <CardsShow userCards={player.cards}></CardsShow>
            {endOfGame ? <div>Score {player.score}</div> : <></>}
          </>
        ))}
      </div>
    </div>
  );
}
