import React from "react";
import { Icon } from "semantic-ui-react";

export function SectionHeader({ currentGame, nplayers, onReturnToGameChoice }) {
  const noCurrentGame = currentGame === "";

  return (
    <div className="sectionHeader">
      <div>
        <h1>{noCurrentGame ? "Game choice" : currentGame}</h1>
        <h4>{nplayers} players</h4>
      </div>
      {noCurrentGame ? (
        <></>
      ) : (
        <div className="rightFlex">
          <div>
            <button
              className="redButton"
              title="End the game for everyone"
              onClick={onReturnToGameChoice}
            >
              <Icon name="sign-out" size="large"></Icon>
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
