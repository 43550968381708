import React, { useState, useEffect, useRef } from "react";

import { useParams } from "react-router";

import { RoomDAO } from "../apis/RoomDAO";

import { decodeRoom } from "../utils/crypto-utils";
import bigLogo from "../logo_big.svg";
import { Choice } from "./Choice";
import { GameSelector } from "./GameSelector";

import { SectionHeader } from "./SectionHeader";
import { Link } from "react-router-dom";

const calculateVideoFrameSize = (numberOfParticipants, l, unit) => {
  if (numberOfParticipants === 1) {
    return { width: 2 * l + unit, height: 2 * l + unit };
  }
  if (numberOfParticipants === 2) {
    return { width: 2 * l + unit, height: 2 * l + unit };
  }

  const scale = Math.floor((numberOfParticipants + 1) / 2);
  return { width: 2 * l + unit, height: scale * l + unit };
};

export const Room = () => {
  const [containerStyle, setContainerStyle] = useState({});
  const [videoCallStyle, setVideoCallStyle] = useState({});
  const [videoFrameStyle, setVideoFrameStyle] = useState({});
  const [encouragementStyle, setEncouragmentStyle] = useState({});
  const [navStyle, setNavStyle] = useState({});
  const [gameStyle, setGameStyle] = useState({});

  const side = 15;

  const [currentGame, setCurrentGame] = useState("");
  const [nParticipants, setNParticipants] = useState(0);

  const apiRef = useRef();
  const participantsRef = useRef();
  const roomRef = useRef();
  const gameRef = useRef();

  const games = ["No Cazzen!", "Avalon", "Lupus", "Hanabi"];
  const { roomId } = useParams();
  const roomCode = decodeRoom(roomId);
  const noCurrentGame = currentGame === "";

  function adjustVideoFrameSize(api) {
    const numberOfParticipants = api.getNumberOfParticipants();
    const size = calculateVideoFrameSize(numberOfParticipants, side, "em");
    api.executeCommand("setTileView", numberOfParticipants > 2);

    setVideoFrameStyle((old) => {
      return { ...old, ...size };
    });
  }

  function saveCaller(user) {
    sessionStorage.setItem("caller", JSON.stringify(user));
  }

  function getCaller() {
    return JSON.parse(sessionStorage.getItem("caller"));
  }

  function savePlayer(user) {
    sessionStorage.setItem("player", JSON.stringify(user));
  }

  function getPlayer() {
    return JSON.parse(sessionStorage.getItem("player"));
  }

  useEffect(() => {
    const options = {
      roomName: roomCode,
      subject: "GamaZam - Meet People, See friends, Play Games",

      parentNode: document.getElementById("jitsi-frame"),
    };

    const api = new window.JitsiMeetExternalAPI("meet.jit.si", options);
    api.addEventListener("videoConferenceJoined", async function (event) {
      adjustStyleForVideoCall();

      adjustVideoFrameSize(api);

      apiRef.current = api;
      //setLocaUser(event);
      saveCaller(event);

      let { room_id, game } = await RoomDAO.getRoomIdAndGame(roomCode);

      if (room_id === undefined) {
        room_id = await RoomDAO.saveRoom(roomCode);
      }

      roomRef.current = room_id;
      gameRef.current = game;
      participantsRef.current = api.getParticipantsInfo();
      setNParticipants(api.getNumberOfParticipants());

      setCurrentGame(game === undefined ? "" : game.name);
    });
    api.addEventListener("participantJoined", function (event) {
      adjustVideoFrameSize(api);
      participantsRef.current = api.getParticipantsInfo();
      setNParticipants(api.getNumberOfParticipants());
    });

    api.addEventListener("participantLeft", function (event) {
      adjustVideoFrameSize(api);
      participantsRef.current = api.getParticipantsInfo();
      setNParticipants(api.getNumberOfParticipants());
    });

    api.addEventListener("videoConferenceLeft", function (event) {
      window.location.reload();
    });

    const unsubscribe = RoomDAO.subscribeToRoom(roomCode, (room, game) => {
      gameRef.current = game;

      if (game !== undefined) {
        // new game
        savePlayer(getCaller());
      }

      setCurrentGame(game === undefined ? "" : game.name);
    });

    return () => {
      unsubscribe();
    };
  }, [roomCode]);

  const adjustStyleForVideoCall = () => {
    setContainerStyle((old) => {
      return { ...old, display: "flex" };
    });
    setVideoCallStyle((old) => {
      return {
        ...old,
        order: -1,
        minWidth: 2 * side + "em",
        marginTop: 25,
        marginLeft: 5,
      };
    });
    setEncouragmentStyle((old) => {
      return { ...old, display: "none" };
    });
    setGameStyle((old) => {
      return { ...old, display: "block", marginLeft: "5%", marginRight: "5%" };
    });
  };

  function handleGameChosen(gameName) {
    RoomDAO.updateCurrentGame(
      roomRef.current,
      gameName,
      participantsRef.current,
      getCaller()
    );
    setNavStyle((old) => {
      return { ...old, display: "none" };
    });
  }
  function handleReturnToGameChoice() {
    RoomDAO.updateCurrentGame(roomRef.current, "", [], getCaller());
    setNavStyle((old) => {
      return { ...old, display: "flex" };
    });
  }

  return (
    <div style={containerStyle}>
      <div className="mainContent">
        <nav style={navStyle}>
          <Link to="/">
            <img src={bigLogo} alt="Home" />
          </Link>
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/">Invite</Link>
            </li>
            <li>
              <Link to="/">About</Link>
            </li>
          </ul>
        </nav>
        <div className="game" style={gameStyle}>
          <SectionHeader
            currentGame={currentGame}
            nplayers={nParticipants}
            onReturnToGameChoice={handleReturnToGameChoice}
          />
          {noCurrentGame ? (
            <Choice onGameChosen={handleGameChosen} games={games}></Choice>
          ) : (
            <>
              <GameSelector
                gameName={currentGame}
                localUser={getPlayer() || getCaller()}
                currentGameInstance={gameRef.current}
              />
            </>
          )}
        </div>
      </div>
      <div className="videoCall" style={videoCallStyle}>
        <div
          id="jitsi-frame"
          className="videoFrame"
          style={videoFrameStyle}
        ></div>
        <h2 style={encouragementStyle}>
          Input your name and join the meeting to enter the Games Room
        </h2>
      </div>
    </div>
  );
};
