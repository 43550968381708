/* eslint-disable no-unused-vars */
import { supabase } from "../supabaseClient";
export const RoomDAO = {
  async saveRoom(code) {
    const { data, error } = await supabase
      .from("room")
      .insert([{ code }])
      .single();

    return data.id;
  },
  async getRoomIdAndGame(code) {
    const { data, error } = await supabase
      .from("room")
      .select("id, game_id")
      .eq("code", code)
      .single();

    if (data === null) {
      return { room_id: undefined, game: undefined };
    }

    const room = data;

    let game = undefined;

    if (room.game_id !== null) {
      const { data, error } = await supabase
        .from("game")
        .select("id, name, participants, creator, seed")
        .eq("id", room.game_id)
        .single();
      game = data;
    }

    return { room_id: room.id, game };
  },

  async updateCurrentGame(room_id, gameName, participants, creator) {
    let game_id = null;
    if (gameName !== "") {
      const { data, error } = await supabase
        .from("game")
        .insert([
          { name: gameName, participants, creator, seed: Math.random() },
        ])
        .single();

      game_id = data.id;
    }

    await this.updateGameId(room_id, game_id);
  },

  async updateGameId(room_id, game_id) {
    const { data, error } = await supabase
      .from("room")
      .update({ game_id })
      .match({ id: room_id });
  },

  async getGame(game_id) {
    const { data, error } = await supabase
      .from("game")
      .select("id, name, participants, creator, seed")
      .eq("id", game_id)
      .single();

    return data === null ? undefined : data;
  },

  subscribeToRoom(code, updateCallback) {
    const roomSubscription = supabase
      .from("room:code=eq." + code)
      .on("UPDATE", async (data) => {
        const room = data.new;

        const game = await this.getGame(room.game_id);

        updateCallback(room.id, game);
      })
      .subscribe();

    return () => {
      supabase.removeSubscription(roomSubscription);
    };
  },
};
