import React from "react";
import iconLogo from "../logo_icon.svg";

export function Choice({ games, onGameChosen }) {
  return (
    <>
      {games.map((game) => (
        <div className="choiceItem">
          <img src={iconLogo} alt="Home"></img>
          <h2>{game}</h2>
          <div className="rightFlex">
            <button
              className="primaryButton"
              onClick={() => {
                onGameChosen(game);
              }}
            >
              Play
            </button>
          </div>
        </div>
      ))}
    </>
  );
}
