import seedrandom from "seedrandom";
import clone from "rfdc/default";

function calculateCountersPerPlayer(numberOfPlayers) {
  if (numberOfPlayers < 3 || numberOfPlayers > 7) {
    return 0;
  } else if (numberOfPlayers === 6) {
    return 9;
  } else if (numberOfPlayers === 7) {
    return 7;
  }
  return 11;
}

function nextId(currentId, ids) {
  const currentIdIndex = ids.findIndex((element) => element === currentId);
  const nextIdIndex = (currentIdIndex + 1) % ids.length;
  return ids[nextIdIndex];
}

function calculateDeck(seed) {
  const cards = [];
  var rng = seedrandom(seed);
  for (let i = 1; i <= 35; i++) {
    cards.push({ value: i, randomOrder: rng() });
  }

  return cards
    .sort((a, b) => {
      return a.randomOrder - b.randomOrder;
    })
    .slice(0, 24)
    .map((card) => card.value);
}

export const Logic = {
  makeInitialState({ participants, seed, creator }) {
    const numberOfPlayers = participants.length;
    const state = {};

    state.deck = calculateDeck(seed);
    state.currentDeckIndex = 0;
    state.counters = 0;
    state.currentPlayer = creator.id;
    state.participantIds = participants.map(
      (participant) => participant.participantId
    );
    state.OfPlayer = {};

    const countersPerPlayer = calculateCountersPerPlayer(numberOfPlayers);
    participants.forEach((participant) => {
      state.OfPlayer[participant.participantId] = {
        displayName: participant.displayName,
        participantId: participant.participantId,
        counters: countersPerPlayer,
        cards: [],
        getScore() {
          let cardsScore = getCardsScore(this.cards);
          return cardsScore - this.counters;
        },
        getSortedCards() {
          return this.cards.sort((a, b) => a - b);
        },
      };
    });

    return state;
  },
  transitionState(state, commandData) {
    const newState = clone(state);

    const id = commandData.id;
    let player = newState.OfPlayer[id];

    switch (commandData.type) {
      case "no":
        player.counters--;
        newState.counters++;
        newState.currentPlayer = nextId(commandData.id, state.participantIds);
        break;
      case "yes":
        player.counters = player.counters + newState.counters;
        newState.counters = 0;
        player.cards.push(newState.deck[newState.currentDeckIndex]);
        newState.currentDeckIndex++;
        break;
      default:
        throw Error("no command named " + commandData.type);
    }

    return newState;
  },
};

export const Commands = {
  Yes(id) {
    return { type: "yes", id: id };
  },
  No(id) {
    return { type: "no", id: id };
  },
};

export function getCardsScore(cards) {
  if (cards.length === 0) {
    return 0;
  }
  const sortedCards = cards.sort((a, b) => a - b);

  let score = sortedCards[0];
  for (let i = 1; i < sortedCards.length; i++) {
    if (sortedCards[i] - sortedCards[i - 1] !== 1) {
      score = score + sortedCards[i];
    }
  }

  return score;
}
