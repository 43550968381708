import { useHistory } from "react-router-dom";
import bigLogo from "../logo_big.svg";

export const Home = () => {
  let history = useHistory();

  function handleClick() {
    history.push("/gameet1testaddress");
  }
  return (
    <div className="home">
      <img src={bigLogo} width="50%" alt="GamaZam" />
      <span>Meet people, see friends, play games.</span>
      <button className="primaryButton" onClick={handleClick}>
        Enter your Room
      </button>
    </div>
  );
};
