/* eslint-disable no-unused-vars */
import { supabase } from "../supabaseClient";

export const CommandDAO = {
  async getCommandDatas(game_id) {
    const { data, error } = await supabase
      .from("command")
      .select("command_data, game_id")
      .eq("game_id", game_id);
    return data.map((x) => x.command_data);
  },

  async saveCommand(command_data, game_id) {
    const { data, error } = await supabase
      .from("command")
      .insert([{ command_data, game_id }]);
  },

  subscribeToCommands(game_id, createCallback) {
    const commandSubscription = supabase
      .from("command:game_id=eq." + game_id)
      .on("INSERT", async (data) => {
        createCallback(data.new.command_data);
      })
      .subscribe();

    return () => {
      supabase.removeSubscription(commandSubscription);
    };
  },
};
